import "./styles.css";

let numbers = [];

function updateNumber() {
    const newNumber = Math.round(Math.random() * 99);
    numbers.push(newNumber);

    document.getElementById("app").innerHTML = newNumber;

    if (numbers.length > 4) {
        numbers.shift();
    }
    if (numbers.length > 3) {

        const sum = numbers[0] + numbers[2];
        // document.getElementById("sum").innerHTML = numbers;
        document.getElementById("sum").innerHTML = `${sum}`;
    } else {
        document.getElementById("sum").innerHTML = '';
    }

    // localStorage.setItem('numbers', JSON.stringify(numbers));
}

document.addEventListener("click", () => {
    updateNumber();
});

document.addEventListener("keydown", (event) => {
    if (event.code === "Space" || event.code === "ArrowDown") {
        updateNumber();
    }
});

window.onload = () => {
    // const savedNumbers = JSON.parse(localStorage.getItem('numbers'));
    // if (savedNumbers && Array.isArray(savedNumbers)) {
    //   numbers = savedNumbers;
    // }
    updateNumber();
};
